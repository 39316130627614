import React from 'react';
import { Button } from 'components';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import './HelpMessage.css';
import DownloadGuide from './DownloadGuide';

interface HelpMessageProps {
  isOpen: boolean;
  onClose: () => void;
}

const HelpMessage: React.FC<HelpMessageProps> = ({ isOpen, onClose }) => {

  return (
    <Popup open={isOpen} onClose={onClose} modal>
      <div className="help-message-container">
        <Button
          variant="btn-primary"
          classname="btn-close"
          onClick={onClose}>
          x
        </Button>
        <div className="weekday-message">
          <h3>Notre équipe est à votre disposition</h3>
          <p>
            Contactez-nous au 01 70 39 17 60 pour une assistance immédiate ! 
          </p>
        </div>
      </div>
      <div className="help-message-footer">
        Gardez votre téléphone près de vous. Il se peut que votre médecin tente de vous appeler.
      </div>
    </Popup>
  );
};

export default HelpMessage;

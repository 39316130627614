import { connect } from 'react-redux'
import WeightDrugsPrescriptionFunnel from 'page/Pages/WeightDrugsPrescriptionFunnel'
import T from 'types'

const mapStateToProps = ({
   prescriptions: { focusedPrescription, lastPrescription, focusedDeliveryOrder, loading },
   storefront,
  }: T.Store) => ({
    loading,
    prescription: focusedPrescription || lastPrescription,
    storefront
})

export default connect(mapStateToProps, null)(WeightDrugsPrescriptionFunnel)

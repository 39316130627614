import Account from './Pages/Account'
import Academy from './Pages/Academy'
import AcademyFunnel from './AcademyFunnel'
import Assistance from './Pages/Assistance'
import Chatbox from './Chatbox'
import ChatboxV2 from './ChatboxV2'
import Checkout from './Pages/Checkout'
import ContextualDoctorProfile from './ContextualDoctorProfile'
import DoctorSelection from './DoctorSelection'
import DeliverPrescription from './Pages/DeliverPrescription'
import DownloadPrescription from './Pages/DownloadPrescription'
import BookingConsultation from './Pages/BookingConsultation'
import PurchasePrescription from './Pages/PurchasePrescription'
import WeightDrugsPrescriptionFunnel from './Pages/WeightDrugsPrecriptionFunnel'
import Home from './Pages/Home'
import JoinCercle from './Pages/JoinCercle'
import LastPrescription from './LastPrescription'
import TherapyPrescriptionBlock from './TherapyPrescriptionBlock'
import LoginForm from './LoginForm'
import MainContainer from './MainContainer'
import Me from './Me'
import MedicalDocuments from './Pages/MedicalDocuments'
import MedicalInfo from './Pages/MedicalInfo'
import Prescriptions from './Pages/Prescriptions'
import Navbar from './Navbar'
import Notifications from './Notifications'
import OnBoarding from './OnBoarding'
import OrdersHistory from './Pages/OrdersHistory'
import Pharmacy from './Pages/Pharmacy'
import Phytotherapy from './Pages/Phytotherapy'
import Prescription from './Pages/Prescription'
import ProgramViewer from './ProgramViewer'
import ProgramManager from './Pages/ProgramManager'
import SecureChallengeWrapper from './SecureChallengeWrapper'
import SleepCalendar from './SleepCalendar'
import UnifiedCheckout from './Pages/UnifiedCheckout'

import Popup from './Popup'
import Sidebar from './Sidebar'

export {
  Academy,
  AcademyFunnel,
  Account,
  Assistance,
  Chatbox,
  ChatboxV2,
  Checkout,
  ContextualDoctorProfile,
  DeliverPrescription,
  DoctorSelection,
  DownloadPrescription,
  BookingConsultation,
  Home,
  JoinCercle,
  LastPrescription,
  LoginForm,
  MainContainer,
  Me,
  MedicalDocuments,
  MedicalInfo,
  Navbar,
  Notifications,
  OnBoarding,
  OrdersHistory,
  Pharmacy,
  Phytotherapy,
  Popup,
  Prescription,
  Prescriptions,
  ProgramManager,
  ProgramViewer,
  PurchasePrescription,
  WeightDrugsPrescriptionFunnel,
  SecureChallengeWrapper,
  Sidebar,
  SleepCalendar,
  TherapyPrescriptionBlock,
  UnifiedCheckout,
}

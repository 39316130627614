import React from 'react'
import T from 'types'
import './styles.sass'
import { Redirect, Route, Switch } from 'react-router-dom'
import Recommendation from './Recommendation'
import { RouteComponentProps } from 'react-router'
import Support from './Support'

interface IProps extends RouteComponentProps<any> {
  prescription: T.Prescription
}

const WeightDrugsPrescriptionFunnel = ({ prescription, match } : IProps) => {
  const url = match.url
  const buildUrl = (path: string) => `${url}/${path}`

  return (
    <Switch>
      <Route path={buildUrl('recommendation')} component={Recommendation} />
      <Route path={buildUrl('support')} render={() => <Support prescription={prescription} />} />
      <Redirect to={buildUrl('recommendation')} />
    </Switch>
  )
}

export default WeightDrugsPrescriptionFunnel

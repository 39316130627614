import React from 'react'
import T from 'types'
import './styles.sass'
import {
  PRESCRIPTION_TYPE_ERECTION_DRUGS,
  PRESCRIPTION_TYPE_HAIRLOSS_DRUGS,
  PRESCRIPTION_TYPE_WEIGHT_DRUGS,
  PRESCRIPTION_TYPE_PHYTOTHERAPY,
} from 'constants/prescriptions'

import DownloadDrugsPrescription from './DownloadDrugsPrescription'
import DownloadPhytotherapyPrescription from './DownloadPhytotherapyPrescription'
import { Loader } from 'components'
type IProps = {
  collectionPrescription?: any[]
  prescription: T.Prescription
  match: any
  getPharmacies: () => void
  getPrescriptionById: (id: number) => void
  getPrescriptions: () => void
  setFocusedPrescription: (prescription: any) => void
  showPopup: (
    {
      classPopup,
      popupTitle,
      popupType,
      popupProps,
    }: {
      classPopup: string
      popupTitle: string
      popupType: string
      popupProps: any
    },
  ) => void
  profile: T.Profile
}
type IState = {
  prescription: any
}

class DownloadPrescription extends React.Component<IProps, IState> {

  componentDidMount() {
    const { getPrescriptionById, prescription, match } = this.props
    let prescriptionId = parseInt(match.params.id)
    if (prescription) {
      if (prescription.id !== prescriptionId) {
        getPrescriptionById(prescriptionId)
      }
    } else {
      getPrescriptionById(prescriptionId)
    }
  }

  render() {
    const { prescription } = this.props
    const { match } = this.props
    let updatedProps = { ...this.props, prescription }
    let prescriptionId = parseInt(match.params.id)
    if (prescription && (prescription.id === prescriptionId)) {
      switch (prescription.prescriptionType) {
        case PRESCRIPTION_TYPE_PHYTOTHERAPY:
          return <DownloadPhytotherapyPrescription {...updatedProps} />
        case PRESCRIPTION_TYPE_ERECTION_DRUGS:
        case PRESCRIPTION_TYPE_HAIRLOSS_DRUGS:
        case PRESCRIPTION_TYPE_WEIGHT_DRUGS:
        default:
          return <DownloadDrugsPrescription {...updatedProps} />
      }
    } else {
      return <Loader />
    }
  }
}

export default DownloadPrescription

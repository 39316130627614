import React, { useState } from 'react'
import { MainContainer } from 'containers'
import { CardBigPill } from 'components/CardPill'
import { Block, Button, SvgIcon } from 'components'
import { getIcon } from 'assets/icons'
import iphoneCercle from 'assets/images/white-iphone-cercle.png'
import './styles.sass'
import { CERCLE_ONBOARDING_URL } from 'constants/config'
import { CookiesHelper } from 'helpers'
import T from 'types'
import tracker from 'utils/trackers'

interface IProps {
  prescription: T.Prescription
}

const Support = ({ prescription }: IProps) => {
  const [isCercleSelected, setIsCercleSelected] = useState(true)

  const handleCercleSubscribe = () => {
    const cercleUrl = `${CERCLE_ONBOARDING_URL}onboarding/medical/selectsubproblem?utm_medium=funnel_prescription&utm_source=charles.co`
    window.open(cercleUrl)
  }

  const handleDownloadPrescription = () => {
    const actualDownloadURL = `${prescription.downloadUrl}?access_token=${CookiesHelper.getPureOauthAccessToken()}`
    window.open(actualDownloadURL, '_blank')
  }

  const handleClick = () => isCercleSelected ? handleCercleSubscribe() : handleDownloadPrescription()

  const handleInvalid = (e: React.FormEvent<HTMLInputElement>) => {
    e.preventDefault()
    e.currentTarget.classList.add('error-input')
    e.currentTarget.parentElement && e.currentTarget.parentElement.classList.add('error-message')
  }

  const handleCheckBoxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      setIsCercleSelected(false)
      e.currentTarget.classList.remove('error-input')
      e.currentTarget.parentElement && e.currentTarget.parentElement.classList.remove('error-message')
    }
  }

  return (
    <MainContainer>
      <div className="h3 mb-4 pb-3">Je choisis mon accompagnement</div>
      <div className="weight-drugs-support">
        <form onSubmit={handleClick}>
          <CardBigPill className="green-card-pill green-prescription-card weight-drugs-support-card" text="Recommandé par votre médecin">
            <div className="weight-drugs-support-title">
              <label className="input-radio-label">
                <input checked={isCercleSelected} title="Option 1 : Accompagnement Charles" type="radio" name="support-input" onClick={() => setIsCercleSelected(true)} />
                <div>Option 1 : Accompagnement Charles</div>
              </label>
              <div className="weight-drugs-support-subtitle">
                À partir de 21€ par mois
              </div>
            </div>
            <div className="d-flex flex-column flex-xl-row-reverse justify-content-xl-end">
              <div className="weight-drugs-support-arguments">
                <div className="d-flex">
                  <SvgIcon classname="icon" icon={getIcon('whiteCheckMark')} />
                  <div>Un coach en nutrition GLP-1 à vos côtés</div>
                </div>
                <div className="d-flex">
                  <SvgIcon classname="icon" icon={getIcon('whiteCheckMark')} />
                  <div>Une aide pour gérer les effets secondaires</div>
                </div>
                <div className="d-flex">
                  <SvgIcon classname="icon" icon={getIcon('whiteCheckMark')} />
                  <div>Une perte de poids plus importante qu’en prenant les GLP-1 seules</div>
                </div>
                <div className="d-flex">
                  <SvgIcon classname="icon" icon={getIcon('whiteCheckMark')} />
                  <div>Le suivi de votre évolution (poids, injection, effets secondaires...) en
                    temps
                    réel par votre médecin
                  </div>
                </div>
              </div>
              <img src={iphoneCercle} className="weight-drugs-support-image" alt="cercle mockup" />
            </div>
          </CardBigPill>
          <Block classname="mb-4">
            <label className="input-radio-label">
              <input checked={!isCercleSelected} type="radio" name="support-input" onClick={() => setIsCercleSelected(false)} />
              Option 2 : J’ai déjà un accompagnement
            </label>
            <label className="input-checkbox-label">
              <input onInvalid={handleInvalid} type="checkbox" required={!isCercleSelected} onChange={handleCheckBoxChange} />
              Je certifie être déjà accompagné sur les sujets de nutrition, et activité physique.
            </label>
          </Block>
          <Button classname="mb-4 mx-auto">
            {isCercleSelected
              ? 'Je choisis l’accompagnement Charles'
              : 'Je continue seul'
            }
          </Button>
          <div className="text-center small-text">
            ✅ Nous vous enverrons votre ordonnance par email après confirmation
          </div>
        </form>
      </div>
    </MainContainer>
  )
}

export default Support

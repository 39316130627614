import T from 'types'
import { Model } from 'survey-core'
import SurveyTracker from '../interface'
import { MediaConfig, MediaSetupTrackingContext } from 'helpers/hooks/useVisioConfiguration';

class CompositeTracker implements SurveyTracker {
  trackers: SurveyTracker[]

  constructor(trackers: SurveyTracker[]) {
    this.trackers = trackers
  }
  identifyUser(userId: string, traits: any): void {
    this.trackers.map((tracker: any) => tracker.identifyUser(userId, traits))
  }
  trackDidEnterOnboarding(trackAddToCart: boolean): void {
    this.trackers.map((tracker: SurveyTracker) => tracker.trackDidEnterOnboarding(trackAddToCart))
  }
  trackQuestionView(sender: Model, step: number, options: any): void {
    this.trackers.map((tracker: SurveyTracker) => tracker.trackQuestionView(sender, step, options))
  }
  trackDidStartPrePaymentSurvey(): void {
    this.trackers.map((tracker: SurveyTracker) => tracker.trackDidStartPrePaymentSurvey())
  }
  trackDidFillPrePaymentSurvey(): void {
    this.trackers.map((tracker: SurveyTracker) => tracker.trackDidFillPrePaymentSurvey())
  }
  trackPostPaymentInfosUpdate(hasDataChanged: boolean): void {
    this.trackers.map((tracker: SurveyTracker) => tracker.trackPostPaymentInfosUpdate(hasDataChanged))
  }
  trackPostPaymentInfosEdit(): void {
    this.trackers.map((tracker: SurveyTracker) => tracker.trackPostPaymentInfosEdit())
  }
  trackAcademyStepView(programName: string, step: number): void {
    this.trackers.map((tracker: SurveyTracker) => tracker.trackAcademyStepView(programName, step))
  }
  trackOnboardingStepView(step: number): void {
    this.trackers.map((tracker: SurveyTracker) => tracker.trackOnboardingStepView(step))
  }
  trackLead(specialty: string): void {
    this.trackers.map((tracker: SurveyTracker) => tracker.trackLead(specialty))
  }
  trackAcademyLead(program: T.Program): void {
    this.trackers.map((tracker: SurveyTracker) => tracker.trackAcademyLead(program))
  }
  trackChoseConsultationType(consultationType: string, medium: string): void {
    this.trackers.map((tracker: SurveyTracker) =>
      tracker.trackChoseConsultationType(consultationType, medium),
    )
  }
  trackChoseMedium(consultationType: string, medium: string): void {
    this.trackers.map((tracker: SurveyTracker) =>
      tracker.trackChoseMedium(consultationType, medium),
    )
  }
  trackChoseDoctor(consultationType: string, medium: string): void {
    this.trackers.map((tracker: SurveyTracker) =>
      tracker.trackChoseDoctor(consultationType, medium),
    )
  }
  trackCreatedConsultation(consultationType: string, medium: string): void {
    this.trackers.map((tracker: SurveyTracker) =>
      tracker.trackCreatedConsultation(consultationType, medium),
    )
  }
  trackDidPayConsultation(
    transactionId: number | any,
    transactionPrice: string,
    consultationType: string,
    medium: string,
    specialty: string,
    paymentMethod?: string,
  ): void {
    this.trackers.map((tracker: SurveyTracker) =>
      tracker.trackDidPayConsultation(transactionId, transactionPrice, consultationType, medium, specialty, paymentMethod),
    )
  }

  trackDidPayAcademyProgram(
    transactionId: number | any,
    transactionPrice: string,
    program: T.Program,
    variant: T.Variant,
    subscriptionEvent?: string,
    orderType?: string
  ): void {
    this.trackers.map((tracker: SurveyTracker) =>
      tracker.trackDidPayAcademyProgram(transactionId, transactionPrice, program, variant, subscriptionEvent, orderType),
    )
  }

  trackProgramPageClicked(
    programSlug: string,
    purchasedProgram: boolean,
    clickOrigin: string,
  ): void {
    this.trackers.map((tracker: SurveyTracker) =>
      tracker.trackProgramPageClicked(programSlug, purchasedProgram, clickOrigin),
    )
  }

  trackPageViewV1(pageTitle: string, pagePath: string): void {
    this.trackers.map((tracker: SurveyTracker) => tracker.trackPageViewV1(pageTitle, pagePath))
  }

  trackPageViewV2(category: string, pagePath: string): void {
    this.trackers.map((tracker: SurveyTracker) => tracker.trackPageViewV2(category, pagePath))
  }

  trackDidPayPhytotherapy(
    revenue: string,
    orderId: string,
    orderType: string,
    firstOfType: boolean,
    products: T.SegmentPhytotherapyProductsProperties[]
  ): void {
    this.trackers.map((tracker: SurveyTracker) =>
      tracker.trackDidPayPhytotherapy(
        revenue,
        orderId,
        orderType,
        firstOfType,
        products
      ),
    )
  }

  trackCameraConfigurationSucceeded(): void {
    this.trackers.map((tracker: SurveyTracker) => tracker.trackCameraConfigurationSucceeded())
  }

  trackCameraConfigurationFailed(errorName: string, errorMessage: string): void {
    this.trackers.map((tracker: SurveyTracker) => tracker.trackCameraConfigurationFailed(errorName, errorMessage))
  }

  trackMediaSetupEvent(config: MediaConfig, trackingContext: MediaSetupTrackingContext): void {
    this.trackers.map((tracker: SurveyTracker) => tracker.trackMediaSetupEvent(config, trackingContext))
  }
  trackMediaSetupEventDeviceMissing(config: MediaConfig, trackingContext: MediaSetupTrackingContext): void {
    this.trackers.map((tracker: SurveyTracker) => tracker.trackMediaSetupEventDeviceMissing(config, trackingContext))
  }
  trackMediaSetupEventSucceeded(config: MediaConfig, trackingContext: MediaSetupTrackingContext): void {
    this.trackers.map((tracker: SurveyTracker) => tracker.trackMediaSetupEventSucceeded(config, trackingContext))
  }
  trackMediaSetupEventSkipped(config: MediaConfig, trackingContext: MediaSetupTrackingContext): void {
    this.trackers.map((tracker: SurveyTracker) => tracker.trackMediaSetupEventSkipped(config, trackingContext))
  }
  trackBrpViewed(): void {
    this.trackers.map((tracker: SurveyTracker) => tracker.trackBrpViewed())
  }
  trackBrpClicked() {
    this.trackers.map((tracker: SurveyTracker) => tracker.trackBrpClicked())
  }
}

export default CompositeTracker

import React from 'react'
import SvgIcon from '../SvgIcon'
import maintenance from 'assets/icons/maintenance.svg'
import './styles.sass'
import { I18n } from 'react-redux-i18n'

export const Maintenance = () => {
  return (
    <div className="container d-flex flex-column justify-content-center align-items-center vh-100 text-center">
      <div className="card shadow-lg p-5">
        <div className="card-body">
          <SvgIcon icon={maintenance} classname="maintenance-icon" />
          <h1 className="h3 display-4 mb-3 mt-3">{I18n.t(`maintenanceMode.title`)}</h1>
          <p className="lead mb-4">{I18n.t(`maintenanceMode.message`)}</p>
        </div>
      </div>
    </div>
  )
}
import React from 'react'

const DownloadGuide = ({ 
  title = "Comment régler le problème ?",
  onDownload
}: {
  title?: string,
  onDownload: () => void
}) => {
  return (
    <a
      className="btn btn-primary"
      href="https://charles-public-assets.s3.eu-west-1.amazonaws.com/doc/autorisations_camera_micro.pdf"
      target="_blank"
      rel="noopener noreferrer"
      onClick={onDownload}
    >
      {title}
    </a>
  )
}

export default DownloadGuide
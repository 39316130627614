import { Block, Button } from 'components'
import { MainContainer } from 'containers'
import React from 'react'
import { CERCLE_ONBOARDING_URL } from 'constants/config'
import { Link } from 'react-router-dom'

const Recommendation = () => {
  const cercleUrl = `${CERCLE_ONBOARDING_URL}onboarding/medical/selectsubproblem?utm_medium=funnel_prescription&utm_source=charles.co`

  return (
    <MainContainer>
      <div className="h3">Mon ordonnance</div>
      <div className="page download-prescription">
        <div className="dowload-prescription-container home-container">
          <Block classname="download-prescription-block">
            <h2 className="recommendation-title">Recommandations officielles</h2>
            <div className="mb-5">
              <p className="mt-4">
                La Haute Autorité de Santé (HAS) et l’Agence Nationale de Sécurité du Médicament
                et des produits de santé (ANSM) demandent d’associer les traitement GLP-1 à un
                <u> régime hypocalorique</u> ainsi qu’à une <u>activité physique*.</u>
              </p>
              <p>
                Il est également recommandé une surveillance rapprochée des effets secondaires
                afin de pouvoir adapter les dosages.
              </p>
            </div>
            <div className="d-flex justify-content-center mb-3 mb-lg-5">
              <Link to="/weight-drugs-prescription/support" className="btn btn-primary">
                J'ai compris
              </Link>
            </div>
            <a href={cercleUrl} target="_blank">
              <u className="d-block text-center font-weight-bold mb-4 mb-lg-5">
                Je veux être accompagné par Charles
              </u>
            </a>
            <div className="download-prescription-links">
              <div className="mb-2">
                <a rel="noopener" target="_blank" href="https://ansm.sante.fr/actualites/analogues-du-glp-1-et-obesite-nous-prenons-des-mesures-pour-securiser-leur-utilisation-en-france">
                  <u>*https://ansm.sante.fr/actualites/analogues-du-glp-1-et-obesite-nous-prenons-des-mesures-pour-securiser-leur-utilisation-en-france</u>
                </a>
              </div>
              <div className="">
                <a rel="noopener" target="_blank" href="https://www.has-sante.fr/jcms/p_3562976/fr/wegovy-semaglutide-obesite#:~:text=Le%20service%20m%C3%A9dical%20rendu%20par,hypocalorique%20et%20d'une%20augmentation">
                  <u>https://www.has-sante.fr/jcms/p_3562976/fr/wegovy-semaglutide-obesite</u>
                </a>
              </div>
            </div>
          </Block>
        </div>
      </div>
    </MainContainer>
  )
}

export default Recommendation

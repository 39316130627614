import 'constants/sass/custom.sass'
import React from 'react'
import ReactDOM from 'react-dom'
import { Portal } from 'react-portal'
import { Provider } from 'react-redux'
import { Me, Notifications, Popup, SecureChallengeWrapper } from 'containers'
import 'constants/sass/index.sass'
import store from '../store'
import 'formdata-polyfill'
import Dinero from 'dinero.js'
import 'survey-core/i18n/french'
import { settings } from 'survey-core'
import { Maintenance } from '../components/Maintenance/Maintenance'
settings.autoAdvanceDelay = 0

Dinero.globalLocale = 'fr-FR'
Dinero.defaultCurrency = 'EUR'

const rootElement = document.getElementById('root-profile')

const RenderedPage = () => {
  if (process.env.REACT_APP_MAINTENANCE === '1') {
    return <Maintenance />
  } else {
    return (
      <Provider store={store}>
        <Me />
        <Portal>
          <Notifications />
          <Popup />
          <SecureChallengeWrapper />
        </Portal>
      </Provider>
    )
  }
}

ReactDOM.render(
  <RenderedPage />,
  rootElement,
)
